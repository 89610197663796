/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "fontsource-roboto";
import "fontsource-fira-sans";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import tr from "vuetify/src/locale/tr";

Vue.use(Vuetify);

const color1 = "#de001c";
const color2 = "#6b226d";
const color3 = "#f1f1f1";
const color4 = "#313131";
const color5 = "#fa9907";
const color6 = "#fbb716";
const color7 = "#89c540";

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: color1,
        secondary: color2,
        accent: color5,
        "soft-gray": color3
      },
      dark: {
        primary: color1,
        secondary: color2,
        accent: color5
      }
    }
  },
  lang: {
    locales: { tr },
    current: "tr"
  }
});
