/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from "@/plugins/firebase";
import "@/types";

const lms = {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    addCourseReg: async (
      context: any,
      prms: { uid: string; courseId: string }
    ) => {
      const defaultUsagePeriod = 30;
      const timestamp = firebase.firestore.Timestamp.now();
      const now = timestamp.toDate();

      const courseRef = await firebase
        .firestore()
        .doc(`/apps/kahev-akademi/courses/${prms.courseId}`)
        .get();

      let finishDate: Date = courseRef.data()?.finishDate
        ? courseRef.data()?.finishDate.toDate()
        : courseRef.data()?.startDate.toDate();
      const days: number = courseRef.data()?.usagePeriod
        ? courseRef.data()?.usagePeriod
        : defaultUsagePeriod;

      // Eğer son ders tarihi geçmiş ise
      // eğitim kullanım süresini bugüne göre belirle
      if (now > finishDate) {
        finishDate = new Date(now);
        finishDate.setDate(finishDate.getDate() + days);
      } else {
        finishDate.setDate(finishDate.getDate() + days);
      }

      // Bitiş tarihini baz alarak kullanıcının aynı
      // derse kayıtlı olup olmadığını kontrol et.
      const cregCheck = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/courseRegs")
        .where("uid", "==", prms.uid)
        .where("courseId", "==", prms.courseId)
        .where("finishDate", "<=", finishDate)
        .get();

      if (cregCheck.empty) {
        await firebase
          .firestore()
          .collection("/apps/kahev-akademi/courseRegs")
          .add({
            uid: prms.uid,
            courseId: prms.courseId,
            startDate: now,
            finishDate: finishDate,
            progress: 0,
            isActive: true
          });
      }
    },

    removeCourseReg: async (context: any, cregId: string) => {
      await firebase
        .firestore()
        .doc(`/apps/kahev-akademi/courseRegs/${cregId}`)
        .delete();
    }
  }
};

export default lms;
