import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vue-meta";
import "@/plugins/vue-notification";
import "@/plugins/json-excel";
import firebase from "@/plugins/firebase";
import "@/types";

Vue.config.productionTip = false;

firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    // Auto sign-in
    await store.dispatch("auth/signIn", {
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      firstName: null,
      lastName: null,
      roles: null
    } as User);
  } else {
    // Sign-out
    await store.dispatch("auth/signOut");
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
});

// new Vue({
//   router,
//   store,
//   vuetify,
//   created() {
//     // Check auth state change
//     firebase.auth().onAuthStateChanged(user => {
//       if (user) {
//         // Auto sign-in
//         store.dispatch("auth/signIn", {
//           id: user.uid,
//           email: user.email,
//           displayName: user.displayName,
//           firstName: null,
//           lastName: null,
//           roles: null
//         } as User);
//       } else {
//         // Sign-out
//         store.dispatch("auth/signOut");
//       }
//     });
//   },
//   render: h => h(App)
// }).$mount("#app");
