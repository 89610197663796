import firebase from "@/plugins/firebase";
import "@/types";

/* eslint-disable @typescript-eslint/no-explicit-any */
const cart = {
  namespaced: true,

  state: {
    cartItemList: [] as CartItem[]
  },

  getters: {
    cartItemList: (state: any) => {
      return state.cartItemList;
    },

    cartItemCount: (state: any) => {
      return state.cartItemList.length;
    },

    cartPrice: (state: any) => {
      let price = 0;

      state.cartItemList.map((item: any) => {
        price += item.price;
      });

      return price;
    }
  },

  mutations: {
    addItem: (state: any, item: CartItem) => {
      const record = state.cartItemList.find(
        (element: any) => element.id == item.id
      );

      if (!record) {
        state.cartItemList.push(item);
      }
    },

    removeItem: (state: any, item: CartItem) => {
      const record = state.cartItemList.find(
        (element: any) => element.id == item.id
      );

      if (record) {
        state.cartItemList.splice(state.cartItemList.indexOf(record), 1);
      }
    },

    setCart: (state: any, cartItemList: CartItem[]) => {
      if (cartItemList) {
        state.cartItemList = cartItemList;
      }
    }
  },

  actions: {
    addItem: (context: any, item: CartItem) => {
      context.commit("addItem", item);
    },

    removeItem: (context: any, item: CartItem) => {
      context.commit("removeItem", item);
    },

    clearCart: (context: any) => {
      context.commit("setCart", []);
    },

    saveCart: async (context: any) => {
      await firebase
        .firestore()
        .doc(
          `/apps/kahev-akademi/shoppingCarts/${context.rootGetters["auth/user"].id}`
        )
        .set({
          items: context.getters["cartItemList"],
          dateUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
    },

    deleteCart: async (context: any) => {
      await firebase
        .firestore()
        .doc(
          `/apps/kahev-akademi/shoppingCarts/${context.rootGetters["auth/user"].id}`
        )
        .delete();
    },

    getCart: async (context: any) => {
      const cartDoc = await firebase
        .firestore()
        .doc(
          `/apps/kahev-akademi/shoppingCarts/${context.rootGetters["auth/user"].id}`
        )
        .get();

      if (cartDoc.exists) {
        const cartItemList = cartDoc.data()?.items;

        context.commit("setCart", cartItemList);
      }
    }
  }
};

export default cart;
