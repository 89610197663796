import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import store from "@/store";
//import firebase from "@/plugins/firebase";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("../layouts/home/Index.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/home/Index.vue")
      },
      {
        path: "egitim-katalogu",
        name: "course-catalog",
        component: () => import("../views/course-catalog/Index.vue")
      },
      {
        path: "egitim-katalogu/:courseUrlCode",
        name: "course-catalog-detail",
        component: () => import("../views/course-catalog/Detail.vue")
      },
      {
        path: "kullanim-sartlari",
        name: "terms-of-use",
        component: () => import("../views/TermsOfUse.vue")
      },
      {
        path: "gizlilik-politikasi",
        name: "privacy-policy",
        component: () => import("../views/PrivacyPolicy.vue")
      },
      {
        path: "kisisel-verilerin-korunmasi",
        name: "personal-data-protection",
        component: () => import("../views/PersonalDataProtection.vue")
      },
      {
        path: "mesafeli-satis-sozlesmesi",
        name: "distance-sales-contract",
        component: () => import("../views/DistanceSalesContract.vue")
      },
      {
        path: "sepetim",
        name: "my-cart",
        component: () => import("../views/shopping/MyCart.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "nasil-yapilir",
        name: "faq",
        component: () => import("../views/Faq.vue")
      }
    ]
  },
  {
    path: "",
    component: () => import("../layouts/account/Index.vue"),
    children: [
      {
        path: "/kaydol",
        name: "sign-up",
        component: () => import("../views/account/SignUp.vue")
      },
      {
        path: "/giris",
        name: "sign-in",
        component: () => import("../views/account/SignIn.vue")
      },
      {
        path: "/parolami-unuttum",
        name: "forgot-password",
        component: () => import("../views/account/ForgotPassword.vue")
      }
    ]
  },
  {
    path: "/ogrenen",
    component: () => import("../layouts/learner/Index.vue"),
    children: [
      {
        path: "egitimlerim",
        name: "learner-home",
        component: () => import("../views/learner/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ayarlar",
        name: "learner-settings",
        component: () => import("../views/learner/Settings.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/egitimler/:courseUrlCode",
    component: () => import("../layouts/course/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "course-home",
        component: () => import("../views/course/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "dersler",
        name: "course-lessons",
        component: () => import("../views/course/Lessons.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tartismalar",
        name: "course-discussions",
        component: () => import("../views/course/discussions/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tartismalar/:id",
        name: "course-discussion",
        component: () => import("../views/course/discussions/View.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("../layouts/admin/Index.vue"),
    children: [
      {
        path: "",
        name: "admin-home",
        component: () => import("../views/admin/courses/Index.vue")
      },
      {
        path: "kullanicilar",
        name: "admin-users",
        component: () => import("../views/admin/users/Index.vue")
      },
      {
        path: "egitimler",
        name: "admin-courses",
        component: () => import("../views/admin/courses/Index.vue")
      },
      {
        path: "egitimler/olustur",
        name: "admin-course-create",
        component: () => import("../views/admin/courses/Create.vue")
      },
      {
        path: "egitimler/:id",
        name: "admin-course-dashboard",
        component: () => import("../views/admin/courses/Dashboard.vue")
      },
      {
        path: "egitimler/:id/duzenle",
        name: "admin-course-edit",
        component: () => import("../views/admin/courses/Edit.vue")
      },
      {
        path: "egitimler/:id/dersler",
        name: "admin-lessons",
        component: () => import("../views/admin/lessons/Index.vue")
      },
      {
        path: "egitimler/:id/dersler/olustur",
        name: "admin-lesson-create",
        component: () => import("../views/admin/lessons/Create.vue")
      },
      {
        path: "egitimler/:id/dersler/:lessonId/duzenle",
        name: "admin-lesson-edit",
        component: () => import("../views/admin/lessons/Edit.vue")
      },
      {
        path: "egitimler/:id/siparisler",
        name: "admin-course-orders",
        component: () => import("../views/admin/courses/Orders.vue")
      },
      {
        path: "siparisler",
        name: "admin-orders",
        component: () => import("../views/admin/orders/Index.vue")
      },
      {
        path: "siparisler/:id",
        name: "admin-order-detail",
        component: () => import("../views/admin/orders/Order.vue")
      },
      {
        path: "kategoriler",
        name: "admin-categories",
        component: () => import("../views/admin/categories/Index.vue")
      }
    ]
  },
  {
    path: "/odeme/:orderId",
    name: "payment-result",
    component: () => import("../views/shopping/PaymentResult.vue")
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/404.vue")
  }
];

const userCanAccess = function(to: Route, isAdminRoute: boolean) {
  const userSignedIn = store.getters["auth/userSignedIn"];
  const user = store.getters["auth/user"];
  const authRole = to.meta.authRole;
  const isAdmin = userSignedIn ? user.roles.indexOf("admin") >= 0 : false;

  // Eğer Route /admin içindeyse authRole'a bakmadan
  // kullanıcının admin rolünde olup olmadığını sorgula
  if (isAdminRoute) {
    if (isAdmin) {
      return true;
    } else {
      return false;
    }
  }

  // Kullanıcı oturum açmış mı?
  if (userSignedIn) {
    // Route için bir rol belirlenmiş ise izin ver
    // Yoksa kullanıcının rolünü kontrol et
    if (!authRole) {
      return true;
    } else {
      // Admin ise izin ver
      if (isAdmin) {
        return true;
      }
      // Kullanıcı istenilen yetki rolünde mi?
      else {
        return user.roles.indexOf(to.meta.authRole.toLowerCase()) >= 0;
      }
    }
  } else {
    return false;
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const isAdminRoute = /^\/admin/.test(to.path);
  const requiresAuth =
    to.matched.some(record => record.meta.requiresAuth) || isAdminRoute;

  if (requiresAuth) {
    if (userCanAccess(to, isAdminRoute)) {
      next();
    } else {
      next({
        name: "sign-in",
        query: { returnUrl: to.path }
      });
    }
  } else {
    next();
  }
});

export default router;
