<template>
  <div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <notifications position="bottom center" classes="notify" />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Hoş geldiniz!",
      titleTemplate: "%s - KAHEV Akademi",
      htmlAttrs: { lang: "tr" },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
};
</script>

<style lang="scss">
// vue-notification
.notify {
  padding: 10px;
  margin: 0 10px 10px;
  font-family: $body-font-family;
  font-size: 90%;
  color: map-get($shades, "white");
  background: map-get($grey, "darken-1") !important;
  border-left: 5px solid $color4 !important;
  z-index: 100;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

  // .notification-title {
  //   // Style for title line
  // }

  // .notification-content {
  //   // Style for content
  // }

  &.warning {
    background: map-get($orange, "lighten-1") !important;
    border-left-color: map-get($orange, "darken-2") !important;
  }

  &.error {
    background: map-get($red, "lighten-1") !important;
    border-color: transparent !important;
    border-left-color: map-get($red, "darken-4") !important;
  }

  &.success {
    background: map-get($green, "lighten-1") !important;
    border-color: transparent !important;
    border-left-color: map-get($green, "darken-2") !important;
  }
}
</style>
