import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// Firebase configs
const firebaseConfig = {
  apiKey: "AIzaSyAfqTM_EcvSMFNYEwrJrzd-SXEvk8Vsxzg",
  authDomain: "kahev-org.firebaseapp.com",
  projectId: "kahev-org",
  storageBucket: "kahev-org.appspot.com",
  messagingSenderId: "652739296918",
  appId: "1:652739296918:web:d5a28e7484bb8f204eccb7",
  measurementId: "G-NJ06ZP3996"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
