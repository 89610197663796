/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from "@/plugins/firebase";
import "@/types";

const auth = {
  namespaced: true,
  state: {
    userSignedIn: false,
    user: {} as User
  },
  getters: {
    user(state: any) {
      return state.user;
    },

    userSignedIn(state: any) {
      return state.userSignedIn;
    }
  },
  mutations: {
    setUserSignedIn(state: any, isSignedIn: boolean) {
      state.userSignedIn = isSignedIn;
    },

    setUser(state: any, user: User) {
      state.user = user;
    }
  },
  actions: {
    async signIn(context: any, user: User) {
      // Get roles of user
      const qs = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/users")
        .where("uid", "==", user.id)
        .get();

      const doc = qs.docs[0];
      if (doc.exists) {
        user.firstName = doc.data()?.firstName;
        user.lastName = doc.data()?.lastName;
        user.displayName = `${user.firstName} ${user.lastName}`;
        user.phone = doc.data()?.phone;
        user.roles = doc.data().roles;
      } else {
        throw "HATA: Kullanıcı profili bulunamadı!";
      }

      context.commit("setUser", user);
      context.commit("setUserSignedIn", true);

      // Get shopping cart
      context.dispatch("cart/getCart", null, { root: true });
    },

    async signOut(context: any) {
      await firebase.auth().signOut();

      context.commit("setUserSignedIn", false);
      context.commit("setUser", null);
    }
  }
};

export default auth;
